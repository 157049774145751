:root {
  --color-headings: #101010;
  --color-paragraphs: #5c5c5c;
  --color-anchors: #ad1457;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--color-paragraphs);
}

/* Typography */
h1,
h2,
h3 {
  color: var(--color-headings);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

p {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

/* Gradients */

.gradient-dark {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    170deg,
    rgba(0, 0, 0, 1) 57%,
    rgba(2, 29, 93, 1) 96%,
    rgba(9, 44, 133, 1) 100%
  );
}

/* Grids */
.grid {
  display: grid;
  grid-gap: 40px;
}

@media screen and (min-width: 1024px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.link--right-aligned {
  display: "flex";
  flex-direction: "row-reverse";
}
